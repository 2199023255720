import { db } from '@/config/firebase';

async function getCompanyById(companyId) {
    let query = db.collection('companies').doc(companyId);
    const doc = await query.get();
    let company = {}
    company = doc.data();
    company.id = doc.id;
    return company;
}

async function getSiblingCompanies(companyId) {
    const companiesObj = {};

    const currCompany = await getCompanyById(companyId);
    const parentCompanyId = currCompany.hasParentCompany ? 
        currCompany.parentCompanyId : currCompany.id;

    const query = db.collection('companies')
        .where("parentCompanyId", "==", parentCompanyId);
    const querySnapshot = await query.get();

    querySnapshot.forEach((doc) => {
        const companyObj = doc.data();
        companyObj.id = doc.id;

        if (companyId !== doc.id) {
            companiesObj[doc.id] = companyObj;
        }
    });

    return companiesObj;
}

async function updateCompanySetupStatus(payload) {
    return db.collection('companies').doc(payload.companyId)
        .set({ companySetupStatus: payload.data }, { merge: true })
        .then(data => {
            return {
                isSuccess: true,
                data: data
            }
        })
        .catch(error => {
            return {
                isSuccess: false,
                data: error
            }
        })
}

export default {
    getSiblingCompanies,
    getCompanyById,
    updateCompanySetupStatus
}